
import { defineComponent, onMounted, ref, reactive, computed } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import { Claim } from "@/modules/claims/claim.model";
import ClaimComponent from "@/modules/claims/ClaimComponent.vue";
import { getClaim } from "@/api/claim.api";
import { getBillingEventsClaims } from "@/api/billingEvent.api";
import { Patient } from "@/modules/patient/patient.model";

export interface Data {
  claim?: Claim;
  patient?: Patient;
  responces: [];
}

export default defineComponent({
  name: "ClaimPage",
  props: ["claimId"],
  components: { ClaimComponent },
  setup(props) {
    const router = useRouter();
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      claim: undefined,
      responces: [],
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Claim");
      const res = await getClaim(props.claimId);
      data.claim = res;
      if (data.claim) {
        data.responces = await getBillingEventsClaims(data.claim.id);
      }
      data.patient = res.encounter.patient;
    });

    return {
      organizationId,
      data,
    };
  },
});
